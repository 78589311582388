import { _buildMessage, _buildApiCall } from './ApiCaller';
// Configurable endpoints. Try to put everything here possible

// List of websocket actions
const websocketActions = {
  AddWidget: 'AddWidget',
  UpdateWidget: 'UpdateWidget',
  DeleteWidget: 'DeleteWidget',
  ClearWidget: 'ClearWidget',
  PurgeWidget: 'PurgeWidget',
  KickUser: 'KickUser',
  AddUser: 'AddUser',
  UpdateUser: 'UpdateUser',
  UserJoinCall: 'UserJoinCall',
  UserLeaveCall: 'UserLeaveCall',
  BufferedKeyUpdate: 'BufferedKeyUpdate',
  WidgetScrollPercent: 'WidgetScrollPercent',
  ChangeSetting: 'ChangeSetting',
  AudioTranscription: 'AudioTranscription',
  SFURequest: 'SFURequest',
  SFUNotification: 'SFUNotification',
  AskForVDBitmap: 'AskForVDBitmap',
  SetCurrentWidget: 'SetCurrentWidget',
};

const apiActions = {
  Login: {
    method: 'POST',
    url: '/auth',
  },
  MagicLink: {
    method: 'POST',
    url: '/auth/magic-link',
  },
  VerifyMagicLink: {
    method: 'GET',
    url: '/users/verify-magic-link',
    useMagiclinkToken: true,
    auth: true,
  },
  BotLogin: {
    method: 'POST',
    url: '/auth/virtual-desktop',
  },
  Signup: {
    method: 'POST',
    url: '/signup',
  },
  SignupInvite: {
    method: 'POST',
    url: '/signup/org-signup',
  },
  SignupAsGuest: {
    method: 'POST',
    url: '/signup/guest',
  },
  ConvertAccount: {
    method: 'POST',
    url: '/signup/convert-account/:id',
  },
  VerifyUser: {
    method: 'POST',
    url: '/signup/verify/:token_code',
  },
  VerifyMe: {
    method: 'POST',
    url: '/signup/verify-me',
    auth: true,
  },
  ForgotPassword: {
    method: 'POST',
    url: '/forgot-password',
  },
  VerifyResetPassword: {
    method: 'GET',
    url: '/forgot-password/verify/:token_code',
  },
  ResetPassword: {
    method: 'PUT',
    url: '/forgot-password/reset/:token_code/:id',
  },
  ChangePassword: {
    method: 'POST',
    url: '/change-password',
    auth: true,
  },
  GetMeetings: {
    method: 'GET',
    url: '/meetings',
    auth: true,
  },
  GetMeeting: {
    method: 'GET',
    url: '/meetings/find/:name',
    auth: true,
  },
  GetRecentRooms: {
    method: 'POST',
    url: '/meetings/recent-rooms',
    auth: true,
  },
  SendContact: {
    method: 'POST',
    url: '/public/contact',
  },
  CheckIfMeetingIsPublic: {
    method: 'GET',
    url: '/public/meeting-is-public/:name',
  },
  CreateMeeting: {
    method: 'POST',
    url: '/meetings',
    auth: true,
  },
  JoinWithPassword: {
    method: 'POST',
    url: '/meetings/:name/join-with-password',
    auth: true,
  },
  TryKnock: {
    method: 'GET',
    url: '/meetings/:name/knock',
    auth: true,
  },
  TransferHost: {
    method: 'POST',
    url: '/meetings/:name/transferhost',
    auth: true,
  },
  GrabHost: {
    method: 'POST',
    url: '/meetings/:name/grabhost',
    auth: true,
  },
  AskHostAccess: {
    method: 'POST',
    url: '/meetings/:name/askhostaccess',
    auth: true,
  },
  NotifyStartMeeting: {
    method: 'POST',
    url: '/meetings/:name/startcall',
    auth: true,
    includeMeetingName: true,
  },
  NotifyJoinMeeting: {
    method: 'POST',
    url: '/meetings/:name/joincall',
    auth: true,
    includeMeetingName: true,
  },
  NotifyLeftMeeting: {
    method: 'POST',
    url: '/meetings/:name/leftcall',
    auth: true,
    includeMeetingName: true,
  },
  NotifyLeaveMeeting: {
    method: 'POST',
    url: '/meetings/:name/leavecall',
    auth: true,
    includeMeetingName: true,
  },
  ToggleLockMeeting: {
    method: 'POST',
    url: '/meetings/:name/lockmeeting',
    auth: true,
  },
  UpdateMeetingPassword: {
    method: 'POST',
    url: '/meetings/updatepassword',
  },
  PokeInSlack: {
    method: 'POST',
    url: '/slack/pokeUser',
    auth: true,
    includeMeetingName: true,
  },
  PostSlackMessage: {
    method: 'POST',
    url: '/slack/postMessage',
    auth: true,
  },
  SendSlackMessage: {
    method: 'POST',
    url: '/slack/sendMessage',
    auth: true,
  },
  AddUserToMeeting: {
    method: 'POST',
    url: '/meetings/:name/add-user',
    auth: true,
  },
  SendEmailInvite: {
    method: 'POST',
    url: '/meetings/:name/invite-users',
    auth: true,
  },
  RemoveUserFromMeeting: {
    method: 'POST',
    url: '/meetings/remove-user',
    auth: true,
    includeMeetingName: true,
  },
  RemoveUsersFromMeeting: {
    method: 'POST',
    url: '/meetings/remove-users',
    auth: true,
    includeMeetingName: true,
  },
  LeaveMeeting: {
    method: 'POST',
    url: '/meetings/leave-room',
    auth: true,
    includeMeetingName: true,
  },
  GenerateCallingProviderToken: {
    method: 'POST',
    url: '/meetings/calling-provider-token',
    auth: true,
    includeMeetingName: true,
  },
  GetUrlMetadata: {
    method: 'POST',
    url: '/files/geturlmetadata',
    auth: true,
  },
  GetUrlHash: {
    method: 'GET',
    url: '/files/urlhash/:url',
    auth: true,
  },
  DownloadUrlHash: {
    method: 'POST',
    url: '/files/urlhash/:url',
    auth: true,
  },
  InviteToMeeting: {
    method: 'POST',
    url: '/meetings/:name/invite',
    auth: true,
  },
  GetAllTeams: {
    method: 'GET',
    url: '/teams',
    auth: true,
  },
  GetTeam: {
    method: 'GET',
    url: '/teams/:name',
    auth: true,
  },
  GetTeamMembers: {
    method: 'GET',
    url: '/teams/:team_id/users',
    auth: true,
  },
  GetWidgetKeyValue: {
    method: 'GET',
    url: '/meetings/getwidgetkeyvalue',
    auth: true,
  },
  UploadImage: {
    method: 'POST',
    url: '/files',
    auth: true,
    includeMeetingName: true,
  },
  UploadFile: {
    method: 'POST',
    url: '/upload',
    auth: true,
    includeMeetingName: true,
  },
  S3PresignedURL: {
    method: 'GET',
    url: '/upload/s3-signed-url',
    auth: false,
    includeMeetingName: false,
  },
  ConvertOffice: {
    method: 'POST',
    url: '/files/convert-office',
    auth: true,
    includeMeetingName: true,
  },
  SetName: {
    method: 'POST',
    url: '/users/set-name',
    auth: true,
    includeMeetingName: true,
  },
  SetAvatar: {
    method: 'POST',
    url: '/users/set-avatar',
    auth: true,
    includeMeetingName: true,
  },
  GenerateToken: {
    method: 'GET',
    url: '/users/generate-token',
    auth: true,
    includeMeetingName: true,
  },
  SetMeetingAvatar: {
    method: 'POST',
    url: '/meetings/set-avatar',
    auth: true,
    includeMeetingName: true,
  },
  SetMeetingAvatarById: {
    method: 'POST',
    url: '/meetings/set-avatar/:id',
    auth: true,
    includeMeetingName: true,
  },
  SetWidgetExposureKey: {
    method: 'POST',
    url: '/meetings/set-exposure-key',
    auth: true,
    includeMeetingName: true,
  },
  DeleteWidgetExposureKey: {
    method: 'POST',
    url: '/meetings/delete-exposure-key',
    auth: true,
    includeMeetingName: true,
  },
  /* Scheduler -Charles */
  AddParticipant: {
    method: 'POST',
    url: '/scheduler/addparticipant',
    auth: true,
  },
  AddProposeDate: {
    method: 'POST',
    url: '/scheduler/addproposedate',
    auth: true,
  },
  VoteSchedule: {
    method: 'POST',
    url: '/scheduler/voteschedule',
    auth: true,
  },
  AddToEventCalendar: {
    method: 'POST',
    url: '/scheduler/addtoeventcalendar',
    auth: true,
  },
  DeleteEvent: {
    method: 'POST',
    url: '/scheduler/deleteevent',
    auth: true,
  },
  RemoveParticipantInPropose: {
    method: 'POST',
    url: '/scheduler/removeParticipantInPropose',
    auth: true,
  },
  AddParticipantInEvent: {
    method: 'POST',
    url: '/scheduler/addParticipantInEvent',
    auth: true,
  },
  RemoveParticipantInEvent: {
    method: 'POST',
    url: '/scheduler/removeParticipantInEvent',
    auth: true,
  },
  AddProposeMeeting: {
    method: 'POST',
    url: '/scheduler/addProposeMeeting',
    auth: true,
  },
  GetSchedulerData: {
    method: 'POST',
    url: '/scheduler/getSchedulerData',
    auth: true,
  },
  NotifyUserInSlack: {
    method: 'POST',
    url: '/scheduler/notifyUserInSlack',
  },
  RemoveProposeDate: {
    method: 'POST',
    url: '/scheduler/removeProposeDate',
    auth: true,
  },
  DeleteProposal: {
    method: 'POST',
    url: '/scheduler/deleteProposal',
    auth: true,
  },
  /* End of Scheduler*/
  ChangeMeetingSetting: {
    method: 'POST',
    url: '/meetings/setting',
    auth: true,
    includeMeetingName: true,
  },
  ChangeMeetingSettings: {
    method: 'POST',
    url: '/meetings/settings',
    auth: true,
    includeMeetingName: true,
  },
  ChangeMeetingBotSettings: {
    method: 'POST',
    url: '/meetings/bot-settings',
    auth: true,
    includeMeetingName: true,
  },
  ChangeMeetingName: {
    method: 'POST',
    url: '/meetings/meeting-name',
    auth: true,
    includeMeetingName: true,
  },
  ChangeMeetingPassword: {
    method: 'POST',
    url: '/meetings/set-password',
    auth: true,
    includeMeetingName: true,
  },
  ActivateGhostMode: {
    method: 'POST',
    url: '/meetings/activate-ghost-mode',
    auth: true,
    includeMeetingName: true,
  },
  SetSubscription: {
    method: 'POST',
    url: '/notifications/add-subscriber',
    auth: true,
    includeMeetingName: true,
  },
  NotifyUser: {
    method: 'POST',
    url: '/notifications/notify',
    auth: true,
    includeMeetingName: true,
  },
  NotifyAll: {
    method: 'POST',
    url: '/notifications/notify-all',
    auth: true,
    includeMeetingName: true,
  },
  NotifyUsersTaskWithEmail: {
    method: 'POST',
    url: '/notifications/task-email-notification',
    auth: true,
    includeMeetingName: true,
  },
  SendPushNotification: {
    method: 'POST',
    url: '/notifications/send-push-notification',
    auth: true,
    includeMeetingName: true,
  },
  ExternalRequest: {
    method: 'POST',
    url: 'public/externalRequest',
  },
  MeetingRecordings: {
    method: 'GET',
    auth: true,
    url: '/meetings/:name/recordings',
  },
  AccessTokens: {
    method: 'GET',
    url: '/users/access-tokens',
    auth: true,
  },
  CheckIfUserHasOnboarded: {
    method: 'GET',
    url: '/users/check-if-user-has-onboarded',
    auth: true,
  },
  SetToken: {
    method: 'POST',
    url: '/users/set-token',
    auth: true,
  },
  RemoveToken: {
    method: 'POST',
    url: '/users/remove-token',
    auth: true,
  },
  RefreshToken: {
    method: 'POST',
    url: '/users/refresh-token',
    auth: true,
  },
  ResendToken: {
    method: 'POST',
    url: '/public/resend-token',
  },
  /* External Conferencing */
  JoinExternal: {
    method: 'POST',
    url: '/bots/start',
    auth: true,
    includeMeetingName: true,
  },
  StopExternal: {
    method: 'POST',
    url: '/bots/stop',
    auth: true,
    includeMeetingName: true,
  },
  /* External Flows */
  ExternalFlowCheckWorkspaceAndToken: {
    method: 'POST',
    url: '/external-flow/check-workspace-and-token',
  },
  /* Clickup */
  GetClickupTeams: {
    method: 'GET',
    url: '/clickup/teams',
    auth: true,
  },
  GetClickupSpaces: {
    method: 'GET',
    url: '/clickup/spaces/:team_id',
    auth: true,
  },
  GetClickupFolders: {
    method: 'GET',
    url: '/clickup/folders/:space_id',
    auth: true,
  },
  GetClickupFolderlessList: {
    method: 'GET',
    url: '/clickup/folderless/:space_id',
    auth: true,
  },
  GetClickupTasks: {
    method: 'GET',
    url: '/clickup/tasks/:list_id',
    auth: true,
  },
  GetClickupOneTask: {
    method: 'GET',
    url: '/clickup/task/:task_id',
    auth: true,
  },
  CreateClickupTask: {
    method: 'POST',
    url: '/clickup/tasks/:list_id',
    auth: true,
  },
  UpdateClickupTask: {
    method: 'PUT',
    url: '/clickup/task/:task_id',
    auth: true,
  },
  UpdateClickupListDescription: {
    method: 'PUT',
    url: '/clickup/list/:list_id',
    auth: true,
  },
  DeleteClickupTask: {
    method: 'DELETE',
    url: '/clickup/task/:task_id',
    auth: true,
  },
  CreateDailyCoRoom: {
    method: 'POST',
    url: '/meetings/create-dailyco-room',
    auth: true,
  },
  DeleteDailyCoRoom: {
    method: 'DELETE',
    url: '/meetings/dailyco-room/:room_name',
    auth: true,
  },
  GetDailyCoMeeting: {
    method: 'GET',
    url: '/meetings/dailyco-room/:room_name',
    auth: true,
  },
  GetClickupListMembers: {
    method: 'GET',
    url: '/clickup/list/:list_id/members',
    auth: true,
  },
  DeleteFileFromMeeting: {
    method: 'DELETE',
    url: '/meetings/:name/files/:file_id',
    auth: true,
  },
  UpdateFileFromMeeting: {
    method: 'PUT',
    url: '/meetings/:name/files/:file_id',
    auth: true,
  },
  CreateNotification: {
    method: 'POST',
    url: '/schedule_notify/create',
    auth: true,
    includeMeetingName: true,
  },
  SaveTranscriptions: {
    method: 'POST',
    url: '/transcriptions/save',
    auth: true,
    includeMeetingName: true,
  },
  GetTranscriptions: {
    method: 'POST',
    url: '/transcriptions/saved',
    auth: true,
    includeMeetingName: true,
  },
  RemoveTranscription: {
    method: 'POST',
    url: '/transcriptions/remove',
    auth: true,
    includeMeetingName: true,
  },
  RenameTranscription: {
    method: 'POST',
    url: '/transcriptions/rename',
    auth: true,
    includeMeetingName: true,
  },
  HighlightTranscription: {
    method: 'POST',
    url: '/transcriptions/highlight',
    auth: true,
    includeMeetingName: true,
  },
  RemoveHighlightTranscription: {
    method: 'POST',
    url: '/transcriptions/removehighlight',
    auth: true,
    includeMeetingName: true,
  },
  SearchTranscription: {
    method: 'POST',
    url: '/transcriptions/search',
    auth: true,
  },
  CreateWidgetData: {
    method: 'POST',
    url: '/widget-data',
    auth: true,
    includeMeetingName: true,
  },
  GetWidgetData: {
    method: 'GET',
    url: '/widget-data',
    auth: true,
    includeMeetingName: true,
  },
  UpdateWidgetData: {
    method: 'PUT',
    url: '/widget-data/:id',
    auth: true,
    includeMeetingName: true,
  },
  DeleteWidgetData: {
    method: 'DELETE',
    url: '/widget-data/:id',
    auth: true,
    includeMeetingName: true,
  },
  GetSyncs: {
    method: 'POST',
    url: '/syncs',
    auth: true,
    includeMeetingName: true,
  },
  GetAllUsers: {
    method: 'GET',
    url: '/users',
    auth: true,
  },
  GetVersion: {
    method: 'POST',
    url: '/syncs/version',
    auth: true,
    includeMeetingName: true,
  },
  // spaces
  GetRoomAndSpaces: {
    method: 'GET',
    url: '/spaces/rooms',
    auth: true,
  },
  CreateSpace: {
    method: 'POST',
    url: '/spaces',
    auth: true,
  },
  AddRoomToSpace: {
    method: 'PUT',
    url: '/spaces/:id',
    auth: true,
  },
  DeleteSpace: {
    method: 'DELETE',
    url: '/spaces/:id',
    auth: true,
  },
  CreateRoomInSpace: {
    method: 'POST',
    url: '/spaces/:id',
    auth: true,
  },
  UpdateSpaceName: {
    method: 'PUT',
    url: '/spaces/:id/name',
    auth: true,
  },
  UpdateSpaceRoomPosition: {
    method: 'PUT',
    url: '/spaces/:id/meetingIds',
    auth: true,
  },
  NotifyUsers: {
    method: 'POST',
    url: '/notifications/notify-users',
    auth: true,
    includeMeetingName: true,
  },
  GetNotifications: {
    method: 'POST',
    url: '/notifications/skip/:skip',
    auth: true,
    includeMeetingName: true,
  },
  UpdateNotifications: {
    method: 'PUT',
    url: '/notifications',
    auth: true,
    includeMeetingName: true,
  },
  CreateRecording: {
    method: 'POST',
    url: '/recordings',
    auth: true,
  },
  GetLatestMeetingSession: {
    method: 'GET',
    url: '/recordings/latest',
    auth: true,
  },
  UpdateRecordingHost: {
    method: 'POST',
    url: '/recordings/change-host',
    auth: true,
  },
  CreateTwilioRoom: {
    method: 'POST',
    url: '/meetings/twilio-room',
    auth: true,
  },
  WhiteBoardSave: {
    method: 'POST',
    url: '/whiteboard/save',
    auth: true,
    includeMeetingName: true,
  },
  Me: {
    method: 'POST',
    url: '/users/me',
    auth: true,
  },
  UpdateTheme: {
    method: 'PUT',
    url: '/users/themes',
    auth: true,
    includeMeetingName: true,
  },
  UpdateWidgetOrder: {
    method: 'PUT',
    url: '/users/widget-order',
    auth: true,
    includeMeetingName: true,
  },
  StripeCreateSession: {
    method: 'POST',
    url: '/stripe/session/:plan',
    auth: true,
  },
  StripeCreateTrial: {
    method: 'POST',
    url: '/stripe/trial',
    auth: true,
  },
  StripeGetSubscription: {
    method: 'GET',
    url: '/stripe/subscription',
    auth: true,
  },
  StripeGetFullBillings: {
    method: 'GET',
    url: '/stripe/fullbillings',
    auth: true,
  },
  StripeGetPlanInfo: {
    method: 'GET',
    url: '/stripe/getplaninfo',
    auth: true,
  },
  StripeGetRoomOwnerLimits: {
    method: 'POST',
    url: '/stripe/roomownerlimits',
    auth: true,
    includeMeetingName: true,
  },
  StripeCancelSubscription: {
    method: 'POST',
    url: '/stripe/cancelsubscription',
    auth: true,
  },
  StripeChangeSubscription: {
    method: 'POST',
    url: '/stripe/switch',
    auth: true,
  },
  StripeChangeBillingDetails: {
    method: 'POST',
    url: '/stripe/changebilling',
    auth: true,
  },
  StripeGetAllProducts: {
    method: 'GET',
    url: '/stripe/getallproducts',
    auth: true,
  },
  StripeGetProductPrices: {
    method: 'GET',
    url: '/stripe/prices/:product',
    auth: true,
  },
  GetUserSubscription: {
    method: 'POST',
    url: '/users/subscription',
    auth: true,
  },
  GetArchivedMeetings: {
    method: 'GET',
    url: '/meetings/archived',
    auth: true,
  },
  UnarchiveMeeting: {
    method: 'POST',
    url: '/meetings/unarchive',
    auth: true,
  },
  GetRecordingBySessionId: {
    method: 'GET',
    url: '/recordings/:sessionId',
    auth: true,
  },
  UpdateRecordingBySessionId: {
    method: 'PUT',
    url: '/recordings/:sessionId',
    auth: true,
  },
  UpdateRecordingRules: {
    method: 'PUT',
    url: '/meetings/twilio-update-rules',
    auth: true,
  },
  GenerateJaasJWT: {
    method: 'POST',
    url: '/meetings/generate-jaas-jwt',
    auth: true,
  },
  GetAllFileFromMeeting: {
    method: 'POST',
    url: '/files/all-meeting-files',
    auth: true,
    includeMeetingName: true,
  },
  RemoveMeetingForever: {
    method: 'POST',
    url: '/meetings/remove-forever',
    auth: true,
  },

  /**
   * GRAPL CALENDAR ENDPOINTS
   */
  CalendarAppointments: {
    method: 'GET',
    url: '/calendar/appointments',
    auth: true,
  },
  CalendarMemberEvents: {
    method: 'POST',
    url: '/calendar/members-with-events',
    auth: true,
  },
  TimeAuthorLogin: {
    method: 'POST',
    url: '/calendar/time-author/login',
    auth: true,
  },
  TimeAuthorUnlink: {
    method: 'GET',
    url: '/calendar/time-author/unlink',
    auth: true,
  },
  CheckAuthorized: {
    method: 'GET',
    url: '/calendar/check-app-authorized/:appName',
    auth: true,
  },
  CreateSchedule: {
    method: 'POST',
    url: '/calendar/schedule',
    auth: true,
  },
  UpdateSchedule: {
    method: 'PUT',
    url: '/calendar/schedule',
    auth: true,
  },
  DeleteSchedule: {
    method: 'DELETE',
    url: '/calendar/schedule',
    auth: true,
  },
  GetOrganizationMembers: {
    method: 'GET',
    url: '/calendar/organization/members',
    auth: true,
  },
  GetMembersByView: {
    method: 'POST',
    url: '/calendar/members/by-view',
    auth: true,
  },
  CreateSharedSpace: {
    method: 'POST',
    url: '/sharedspace/create',
    auth: true,
  },
  GetSharedSpaces: {
    method: 'GET',
    url: '/sharedspace',
    auth: true,
  },
  AddRoomToSharedSpace: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/add-room-to-root',
    auth: true,
  },
  CreateFolderToSharedSpace: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/add-folder',
    auth: true,
  },
  AddRoomToSharedSpaceFolder: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/add-room-to-folder',
    auth: true,
  },
  RenameSharedSpaceFolder: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/rename-folder',
    auth: true,
  },
  RenameSharedSpace: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/rename',
    auth: true,
  },
  AddUserToSharedSpaceByMail: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/add-user-by-mail',
    auth: true,
  },
  AddUserToSharedSpaceFolderByMail: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/add-user-to-folder-by-mail',
    auth: true,
  },
  AddUserToSharedSpaceRoomByMail: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/add-user-to-room-by-mail',
    auth: true,
  },
  AddUserToSharedSpaceFolderRoomByMail: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/add-user-to-folder-room-by-mail',
    auth: true,
  },
  RemoveUserFromSharedSpace: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/remove-user',
    auth: true,
  },
  RemoveUserFromSharedSpaceFolder: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/remove-user-from-folder',
    auth: true,
  },
  RemoveUserFromSharedSpaceRoom: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/remove-user-from-room',
    auth: true,
  },
  RemoveUserFromSharedSpaceFolderRoom: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/remove-user-from-folder-room',
    auth: true,
  },
  MoveRoomToSameSharedSpace: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/move-room-to-same-sharedspace',
    auth: true,
  },
  MoveRoomToNewSharedSpace: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/move-room-to-new-sharedspace',
    auth: true,
  },
  MoveRoomToSharedSpaceFolder: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/move-room-to-sharedspace-folder',
    auth: true,
  },
  MoveRoomToSharedSpaceFolderToFolder: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/move-room-to-sharedspace-folder-to-folder',
    auth: true,
  },
  MoveRoomToSharedSpaceFromRoot: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/move-room-to-sharedspace-from-root',
    auth: true,
  },
  MoveRoomToSharedSpaceFolderFromRoot: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/move-room-to-sharedspace-folder-from-root',
    auth: true,
  },
  MoveRoomToSharedSpaceFolderToRoot: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/move-room-to-sharedspace-folder-to-root',
    auth: true,
  },
  RemoveFolderFromSharedSpace: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/remove-folder',
    auth: true,
  },
  DeleteSharedSpace: {
    method: 'DELETE',
    url: '/sharedspace/:sharedSpaceId',
    auth: true,
  },
  GetInvitedUsersInSharedSpace: {
    method: 'GET',
    url: '/sharedspace/:sharedSpaceId/invited-users',
    auth: true,
  },
  GetInvitedUsersInSharedSpaceFolder: {
    method: 'GET',
    url: '/sharedspace/:sharedSpaceId/invited-users/:folderId',
    auth: true,
  },
  CancelSharedSpaceInvitation: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/cancel-invitation',
    auth: true,
  },
  ResendSharedSpaceInvitation: {
    method: 'POST',
    url: '/sharedspace/:sharedSpaceId/resend-invitation',
    auth: true,
  },
};

// Feel free to call this directly
export default function LoopApi(connection, action, data = {}, parameters = [], form) {
  const foundAction = websocketActions[action];
  const foundEndpoint = apiActions[action];
  // console.log({ foundAction, foundEndpoint, action })
  if (!foundAction && !foundEndpoint) {
    throw new Error(`Endpoint not found!!! Action: ${action}`);
  }

  return new Promise(async (done, err) => {
    try {
      let resp;
      if (!!foundAction) {
        if (connection !== 'main') {
          return console.error('no connection given', connection, action, data);
        }
        resp = await _buildMessage(connection, foundAction, data);
      } else {
        // console.log('API CALL', { foundEndpoint, data, parameters })
        resp = await _buildApiCall(foundEndpoint, data, parameters, form);
      }
      done(resp);
    } catch (e) {
      err(e);
    }
  });
}
